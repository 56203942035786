<template>
    <div class="container py-5">
        <div class="text-center">
            <h1 class="display-4 font-weight-bold mb-3">
                {{ state.data.title }}
            </h1>
            <p
                v-if="state.data.applicable"
                class="lead text-muted">
                {{ state.data.applicable }}
            </p>
        </div>

        <!-- Konten Markdown -->
        <div
            class="markdown-body mt-5"
            v-html="state.renderedMarkdown"/>
    </div>
</template>

<script>
import {
    defineComponent,
    onMounted,
    reactive,
} from 'vue'
import { useRoute } from 'vue-router'
import markdownIt from 'markdown-it'
import matter from 'gray-matter'

export default defineComponent({
    setup() {
        const route = useRoute()
        const state = reactive({
            data: {},
            renderedMarkdown: '',
        })

        const fetchDataList = async () => {
            const pageSlug = route.params.slug
            try {
                // Import file markdown
                const file = await import(`@/docs/${pageSlug}.md?raw`)

                console.log(file)

                // gray-matter parsing frontmatter + body
                const { data, content } = matter(file.default || file)

                console.log(data, content)
                
                const md = markdownIt()
                state.data = data
                state.renderedMarkdown = md.render(content)
            } catch (error) {
                state.renderedMarkdown = '<h2>404 Not Found</h2><p>Page not found!</p>'
            }
        }

        onMounted(async() => {
            await fetchDataList()
        })

        return {
            state,
        }
    },
})
</script>
